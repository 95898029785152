import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import styled from "styled-components"
import Logo from "../components/logo"
import NavigationLinks from "../components/navigation/navigationLinks"
import SideDrawer from "./navigation/sideDrawer"
import MobileNav from "./navigation/mobileNav"
const HeaderWrapper = styled.header`
  display: block;
  position: fixed;
  top: 0px;
  display: block;
  background: black;
  width: 100%;
  height: 80px;
  z-index: 999;
  .logo-mobile {
    display: block;
    width: 115px;
    background: white;
    padding: 1em;
    top: 0px;
    .gatsby-image-wrapper {
    }
  }
  @media only screen and (min-width: 797px) {
    .logo-mobile {
      display: none;
    }
  }
`

const Header = ({ siteTitle }) => {
  const [toggleNav, setToggled] = useState(false)
  const [shouldShrink, setShouldShrink] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
      setShouldShrink(e.target.documentElement.scrollTop > scrollTop)
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  const toggleHamburger = () => setToggled(!toggleNav)
  return (
    <HeaderWrapper className={shouldShrink ? "shrink" : ""}>
      {/* <p>{shouldShrink.toString()}</p> */}
      <div
        style={{
          display: "flex",
          maxWidth: "960px",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <NavigationLinks shrinkLogo={shouldShrink} />
        <div className="logo-mobile">
          <Logo />
        </div>

        <MobileNav click={toggleHamburger} change={toggleNav}></MobileNav>
      </div>
      <SideDrawer change={toggleNav} />
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
