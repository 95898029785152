/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import Header from "./header"
import "./layout.css"
const GlobalStyle = createGlobalStyle`
h1, h2, h3, h4 {
  font-family: "Western Bang Bang";
}
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>
      <footer
        style={{
          margin: "50px auto 5px auto",
          textAlign: "center",
          fontSize: 12,
          fontFamily: "Segoe UI",
        }}
      >
        Zicatela Woodfire Mexican © {new Date().getFullYear()}, Made with
        &hearts; by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
          href="https://www.pixelize.com.au/"
        >
          Pixelize
        </a>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
