import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const SideDrawerWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 79px;
  width: 100%;
  height: auto;

  nav {
    display: block;
    font-size: 20px;
    text-align: center;
  }
  a {
    display: inline-block;
    padding: 15px 15px;

    font-family: "Western Bang Bang";
    font-size: 30px;
    text-decoration: none;
    color: black;
  }
  nav {
  }
  @media only screen and (min-width: 797px) {
    display: none;
  }
`

const SideDrawer = props => (
  <SideDrawerWrapper className={props.change === true ? "open" : "close"}>
    <nav>
      <AnchorLink to="/#home">Home</AnchorLink>
      <AnchorLink to="/#about">About</AnchorLink>
      <AnchorLink to="/#menu">Menus</AnchorLink>
      <AnchorLink to="/#contact">Contact</AnchorLink>
    </nav>
  </SideDrawerWrapper>
)

export default SideDrawer
