import React from "react"
import styled from "styled-components"

import Logo from "../logo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSpring, animated } from "react-spring"
const NavWrapper = styled.nav`
  display: none;
  font-family: Segoe UI;
  ${"" /* margin: 0 auto; */}
  width: 100%;

  .logo {
    padding: 1em;

    background: white;
  }
  a {
    font-family: "Western Bang Bang";
    color: white;
    font-size: 30px;

    margin: 28px auto 0px auto;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
  }
  a:hover {
    transform: scale(1.2);
    color: #bdd230;
  }
  @media only screen and (min-width: 797px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const NavigationLinks = props => {
  const styleProps = useSpring({ width: props.shrinkLogo ? 115 : 200 })
  return (
    <NavWrapper>
      <AnchorLink to="/#home">Home</AnchorLink>
      <AnchorLink to="/#about">About</AnchorLink>
      
      {/* className={props.shrinkLogo ? "shrink-logo" : "logo"} */}
      <animated.div className="logo" style={styleProps}>
        <Logo />
      </animated.div>
      <AnchorLink to="/#menu">Menus</AnchorLink>
      <AnchorLink to="/#contact">Contact</AnchorLink>

    </NavWrapper>
  )
}
export default NavigationLinks
